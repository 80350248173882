import React from "react";

class Project extends React.Component {
  render() {
    const projectThumb = {
      width: 180,
      height: 180,
      paddingRight: 12,
    }
    const projectLink = {
      position: 'absolute',
      marginTop: 0,
    }

    return (
      <div>
        <h2>
          Project Links
        </h2>
        <hr/>
        <div style={{display: 'flex'}}>
          <img src="/images/project-amovent.png"
               alt="Picture of amovent.com landing page"
               style={ projectThumb }/>
          <div>
            <h3 style={ projectLink }><a href="https://amovent.com" target="_blank">amovent.com</a></h3>
            <h4>Social Sport Network
              <br/>Cooperate with people due to your interests, create and attend events you're interested in!
            </h4>
            <p>Social network aimed to create teams, organize events, participate in competitions and of course just to
              be in sport theme.
              You create badges due to your interests - volleyball, football, wrestling, curling, cycling (etc.) - and
              you'll be notified
              about that kinds of events via email. That way you can see the participants, organizers
              and any info about event,chat with other participants, decide to participate or ignore the particular
              event - it's up to you!</p>
          </div>
        </div>
        <hr/>
        <div>
          <h3>[inactive] <a href="https://bitbucket.org/barbariania/lego-price-scrapper" target="_blank">Lego Price
            Scrapper (*.by domain)</a></h3>
          <h4>Data scrapper for LEGO Sets in Belarus by predefined sites</h4>
          <p>Finds the desired set by its theme (like "Technic", "City", "Mindstorms") and set name (like 42114, 60249,
            51515)
            and shows the results with name, link and price by sites. You can choose a cheaper or more preferable for
            you.</p>
        </div>
        <hr/>
        <div style={{display: 'flex'}}>
          <img src="/images/project-paint.png"
               alt="Image of paint project page with a painted number and a guess attempt"
               style={ projectThumb }/>
          <div>
            <h3 style={ projectLink }><a href={"/paint"}>ML paint</a></h3>
            <h4>(prototype, learning, no desired direction now)</h4>
            <p>Paint one of the figures that ML can guess and let's see if it succeed in it!</p>
          </div>
        </div>
        <hr/>
        <div>
          <h3><a href="http://coherentcamel.com" target="_blank">coherentcamel.com</a></h3>
          <h4>Non-standard game or world. Perhaps will be in Python + OpenGL</h4>
          <p>Right now I'm on investigation phase. Not really sure about idea and its implementation. Want to create an
            own world of 4d space with 0- or 2-dimensional time) </p>
        </div>
      </div>
    );
  }
}

export default Project
